import BaseHandler from "./BaseHandler";
import CoreHandler from "./CoreHandler";

class CanvasHandler extends BaseHandler {
  public coreHandler: CoreHandler;

  constructor(coreHandler: CoreHandler) {
    super();

    this.coreHandler = coreHandler;
  }

  initialize(): void {
    const { canvas } = this.coreHandler;

    // disable group selection
    canvas.selection = false;

    // make sure object renders can be controlled by the user
    canvas.preserveObjectStacking = true;

    // ez debugging
    (window as any).getResult = () => {
      const canvas = this.coreHandler.canvas;
      const result = canvas.toJSON();
      return result;
    };

    (window as any).getObjects = () => {
      const canvas = this.coreHandler.canvas;
      const result = canvas.getObjects();
      return result;
    };
  }

  destroy(): void {}

  mousedown(event: fabric.IEvent) {}

  mouseover(event: fabric.IEvent) {
    if (event.target === null) {
      return;
    }

    (event.target as any)._renderControls(
      (this.coreHandler.canvas as any).contextTop,
      {
        hasControls: false,
      }
    );
  }

  mouseout(event: fabric.IEvent) {
    this.coreHandler.canvas.clearContext(
      (this.coreHandler.canvas as any).contextTop
    );
  }
}

export default CanvasHandler;
