import { DEFAULT_WORKSPACE_SNAPPING } from "@utils/constant";
import BaseHandler from "./BaseHandler";
import CoreHandler from "./CoreHandler";
import { AligningGuidelines } from "@lib/fabric/guidelines";

class SnappingHandler extends BaseHandler {
  public coreHandler: CoreHandler;

  public snapping: AligningGuidelines | undefined;

  public isEnabled: boolean = false;

  constructor(coreHandler: CoreHandler) {
    super();

    this.coreHandler = coreHandler;
  }

  initialize(): void {
    const { canvas } = this.coreHandler;

    this.snapping = new AligningGuidelines(canvas, {
      margin: 10,
    });

    if (DEFAULT_WORKSPACE_SNAPPING) {
      this.enable();
    }
  }

  enable(): void {
    this.snapping?.enable();
    this.isEnabled = true;
    this.emit("snapping:changed", this.isEnabled);
  }

  disable(): void {
    // TODO - not sure why this doesn't work when it's called on UI
    this.snapping?.disable();
    this.isEnabled = false;
    this.emit("snapping:changed", this.isEnabled);
  }

  destroy(): void {
    this.disable();
  }
}

export default SnappingHandler;
