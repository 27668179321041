import { fabric } from "@utils/fabric";
import { PointMap, Point, FabricObject } from "../typedefs";

export function getDistance(a: number, b: number) {
  return Math.abs(a - b);
}

export function getDistanceList(point: Point, list: Point[], type: "x" | "y") {
  let dis = Infinity;
  let arr: Point[] = [];
  for (const item of list) {
    const v = getDistance(point[type], item[type]);
    if (dis > v) {
      arr = [];
      dis = v;
    }
    if (dis === v) {
      arr.push(item);
    }
  }
  return { dis, arr };
}

export function getPointMap(target: FabricObject): PointMap {
  const coords = target.getCoords(true);

  return {
    tl: coords[0],
    tr: coords[1],
    br: coords[2],
    bl: coords[3],
    mt: new fabric.Point(
      (coords[0].x + coords[1].x) / 2,
      (coords[0].y + coords[1].y) / 2
    ),
    mr: new fabric.Point(
      (coords[1].x + coords[2].x) / 2,
      (coords[1].y + coords[2].y) / 2
    ),
    mb: new fabric.Point(
      (coords[2].x + coords[3].x) / 2,
      (coords[2].y + coords[3].y) / 2
    ),
    ml: new fabric.Point(
      (coords[3].x + coords[0].x) / 2,
      (coords[3].y + coords[0].y) / 2
    ),
  };
}

export function getContraryMap(target: FabricObject): PointMap {
  const aCoords = target.aCoords ?? target.calcCoords();
  return {
    tl: aCoords.br,
    tr: aCoords.bl,
    br: aCoords.tl,
    bl: aCoords.tr,
    mt: new fabric.Point(
      (aCoords.br.x + aCoords.bl.x) / 2,
      (aCoords.br.y + aCoords.bl.y) / 2
    ),
    mr: new fabric.Point(
      (aCoords.bl.x + aCoords.tl.x) / 2,
      (aCoords.bl.y + aCoords.tl.y) / 2
    ),
    mb: new fabric.Point(
      (aCoords.tl.x + aCoords.tr.x) / 2,
      (aCoords.tl.y + aCoords.tr.y) / 2
    ),
    ml: new fabric.Point(
      (aCoords.tr.x + aCoords.br.x) / 2,
      (aCoords.tr.y + aCoords.br.y) / 2
    ),
  };
}
