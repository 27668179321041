import { FabricObject } from "../typedefs";
import { fabric } from "@utils/fabric";

export function getObjectsByTarget(target: FabricObject) {
  const objects = new Set<FabricObject>();
  const canvas = target.canvas;
  if (!canvas) return objects;
  const children =
    target instanceof fabric.ActiveSelection
      ? (target as fabric.ActiveSelection).getObjects()
      : [target];

  // iterate objects including the workspace rectangle
  const getObjects = (canvas as any)._getObjects as fabric.Canvas["getObjects"];
  getObjects.call(canvas).forEach((o) => {
    if (!o.isOnScreen()) return;
    if (!o.visible) return;
    if (o.constructor === fabric.Group) {
      collectObjectsByGroup(objects, o as fabric.Group);
      return;
    }
    objects.add(o);
  });

  deleteObjectsByList(objects, children);
  return objects;
}

function deleteObjectsByList(objects: Set<FabricObject>, list: FabricObject[]) {
  for (const target of list) {
    if (target.constructor === fabric.Group) {
      deleteObjectsByList(objects, (target as fabric.Group).getObjects());
    } else {
      objects.delete(target);
    }
  }
}

function collectObjectsByGroup(objects: Set<FabricObject>, g: fabric.Group) {
  const children = g.getObjects();
  for (const child of children) {
    if (!child.visible) continue;
    if (child.constructor === fabric.Group) {
      collectObjectsByGroup(objects, child as fabric.Group);
      continue;
    }
    objects.add(child);
  }
}
