export const DOCS_URL = "https://docs.copicake.com";

export const CHANGELOG_URL = "https://copicake.com/blog/posts/changelog";

export const INTEGRATION_MAKE_ARTICLE_URL =
  "https://blog.copicake.com/blog/posts/no-code-integration-with-make-integromat";

export const MIN_PROPERTY_WIDTH = 1;

export const MIN_PROPERTY_HEIGHT = 1;

export const DEFAULT_STROKE_WIDTH = 0;

export const DEFAULT_TEMPLATE_WIDTH = 600;

export const DEFAULT_TEMPLATE_HEIGHT = 600;

export const DEFAULT_CANVAS_BACKGROUND_COLOR = "#ffffff";

export const DEFAULT_WORKSPACE_BACKGROUND_COLOR = "#efefef";

export const DEFAULT_WORKSPACE_MODE = "view";

export const DEFAULT_WORKSPACE_SNAPPING = true;

export const DEFAULT_TEMPLATE_TITLE = "Untitled";

export const WORKSPACE_ID = "workspace";

export const CANVAS_ID = "canvas";

export const MAX_TEMPLATE_WIDTH = 3000;

export const MAX_TEMPLATE_HEIGHT = 3000;

export const GOOGLE_FONT_API_KEY = "AIzaSyB-UIS_2qASH86fnPs7zf7Oa3IRMtdwPKw";

export const ROLLBAR_TOKEN = "f57099915b784864821644195435cbfd";

export const UNSPLASH_APP_NAME = "Copicake";

export const UNSPLASH_TOKEN = "0xm2plvmTsZDu7GEP3AG4nxVNJJfJSy6hgn8bFyMp0g";

export const PEXELS_TOKEN =
  "B4yvBS0IECIWE57IdtA7PhYWFktoJB3BakCTYKkhldPObdy0atstQD83";

export const DEFAULT_CANVAS_JSON =
  '{"version":"5.3.0","objects":[],"background":"#ffffff"}';

export const DEFAULT_FONT_FAMILY = "Times New Roman";

export const DEFAULT_FONT_OBJECT = {
  category: "sans-serif",
  name: DEFAULT_FONT_FAMILY,
  sane: DEFAULT_FONT_FAMILY.toLowerCase().replace(" ", "_"),
  cased: DEFAULT_FONT_FAMILY.toLowerCase(),
  variants: ["0,400", "0,700", "1,400", "1,700"],
};

export const PRICING = {
  BASIC: {
    MONTHLY_PRODUCT_ID: "762860",
    YEARLY_PRODUCT_ID: "762866",
  },
  ADVANCED: {
    MONTHLY_PRODUCT_ID: "762861",
    YEARLY_PRODUCT_ID: "762867",
  },
  PREMIUM: {
    MONTHLY_PRODUCT_ID: "762862",
    YEARLY_PRODUCT_ID: "762868",
  },
};

export function getWaterMarkText(
  width: number,
  height: number
): Record<string, unknown> {
  const text = PREVIEW_WATER_MARK_TEXT;
  text.left = Math.round(width / 2);
  text.top = Math.round(height / 2);
  return text;
}

export const PREVIEW_WATER_MARK_TEXT = {
  type: "textbox",
  version: "5.3.0",
  originX: "center",
  originY: "center",
  left: 186,
  top: 283.68,
  width: 227,
  height: 31.64,
  fill: "#000000",
  stroke: null,
  strokeWidth: 1,
  strokeDashArray: null,
  strokeLineCap: "butt",
  strokeDashOffset: 0,
  strokeLineJoin: "miter",
  strokeUniform: false,
  strokeMiterLimit: 4,
  scaleX: 1,
  scaleY: 1,
  angle: 0,
  flipX: false,
  flipY: false,
  opacity: 0.3,
  shadow: null,
  visible: true,
  backgroundColor: "",
  fillRule: "nonzero",
  paintFirst: "fill",
  globalCompositeOperation: "source-over",
  skewX: 0,
  skewY: 0,
  name: "text-e5neo1",
  fontFamily: DEFAULT_FONT_FAMILY,
  fontWeight: "bold",
  fontSize: 28,
  text: "Made by Copicake",
  underline: false,
  overline: false,
  linethrough: false,
  textAlign: "left",
  fontStyle: "normal",
  lineHeight: 1.16,
  textBackgroundColor: "",
  charSpacing: 0,
  styles: {},
  direction: "ltr",
  path: null,
  pathStartOffset: 0,
  pathSide: "left",
  pathAlign: "baseline",
  minWidth: 20,
  splitByGrapheme: false,
};
